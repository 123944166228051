<template>
    <div id="progress-bar" class="m-auto">
        <div class="row justify-content-center position-relative p-0">
            <div class="lines-container d-flex justify-content-center position-absolute w-100 h-100">
                <div class="col h-100 p-0" v-for="i in currentProgressPlan.length - 1" :key="i">
                    <div class="w-100 h-50 position-absolute line-prop" :class="{ 'selected-step': i < currentStep }"></div>
                </div>
            </div>
            <div class="circles-container justify-content-between position-absolute w-100 h-100 d-none d-md-flex">
                <div class="position-relative" v-for="(value, index) in currentProgressPlan" :key="index">
                    <router-link
                        :to="index < currentStep && currentStore && currentStore.url ? createLink(currentStore.url, value.src) : $route.path"
                        :class="{ 'not-pointer': !(index < currentStep) }">
                        <div class="circle-step noto-sans-kr" :class="{ 'selected-step': index < currentStep }">{{ index + 1 }}</div>
                        <span class="noto-sans-14 circle-span" :class="{ 'selected-step': index < currentStep }">{{ value.title }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import findIndex from "lodash/findIndex";

export default {
    name: "progress-bar",
    data() {
        return {
            progressPlans: [
                [
                    { type: "FIND_JEWELER", title: "Find a Jeweler", src: null },
                    { type: "RINGS", title: "Select Setting", src: "engagement-rings" },
                    { type: "DIAMONDS", title: "Select Diamond", src: "diamonds" },
                    { type: "WISHLIST", title: "Try on & Pay in Store", src: "wishlist" },
                ],
                [
                    { type: "FIND_JEWELER", title: "Find a Jeweler", src: null },
                    { type: "DIAMONDS", title: "Select Diamond", src: "diamonds" },
                    { type: "RINGS", title: "Select Setting", src: "engagement-rings" },
                    { type: "WISHLIST", title: "Try on & Pay in Store", src: "wishlist" },
                ],
            ],
        };
    },
    computed: {
        ...mapState("willyou", ["currentStore"]),
        ...mapGetters("willyou", ["chosenDiamond", "chosenRing"]),
        currentProgressPlan() {
            return (this.chosenDiamond || this.$route.name === "diamonds" || this.$route.name === "diamond") && !this.chosenRing
                ? this.progressPlans[1]
                : this.progressPlans[0];
        },
        currentStep() {
            switch (this.$route.name) {
                case "wishlist":
                    return 4;
                case "diamonds":
                    return findIndex(this.currentProgressPlan, { type: "DIAMONDS" }) + 1;
                case "product-diamond":
                    return findIndex(this.currentProgressPlan, { type: "DIAMONDS" }) + 1;
                case "rings":
                    return findIndex(this.currentProgressPlan, { type: "RINGS" }) + 1;
                case "product-ring":
                    return findIndex(this.currentProgressPlan, { type: "RINGS" }) + 1;
                default:
                    return 1;
            }
        },
    },
    methods: {
        createLink(storeurl, src) {
            return src ? `/jewelers/${storeurl}/${src}` : "/jewelry-stores";
        },
    },
};
</script>

<style lang="scss" scoped>
#progress-bar {
    width: 714px;
    height: 73px;
    margin-top: 100px !important;
    margin-bottom: 100px !important;

    img {
        width: auto;
        height: auto;
        max-width: 700px;
    }
    .line-prop {
        margin-top: 22.5px;
        border-top-width: 4px;
        border-top-color: #e8ebeb;
        border-top-style: solid;
    }
    .circle-step {
        border-radius: 50%;
        border: 4px #e8ebeb solid;
        height: 45px;
        width: 45px;
        text-align: center;
        vertical-align: middle;
        background-color: #fff;
        padding-top: 4px;
        color: #e8ebeb;
    }
    .circle-span {
        color: #e8ebeb;
        position: absolute;
        left: -50%;
        margin-top: 5px;
        white-space: nowrap;
    }
    @media (max-width: 992px) {
        width: 100% !important;
        height: 73px;
        margin-top: 100px !important;
        /*margin-bottom: 100px !important;*/
    }
    @media (max-width: 767px) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .not-pointer {
        cursor: default !important;
    }
}
</style>
