<template>
    <div id="popup-area">
        <transition name="fade">
            <div class="modal show d-block" id="popup">
                <div class="modal-dialog modal-dialog-centered" :class="{ 'stick-to-top': isIframeMode }">
                    <div class="modal-content">
                        <div class="popup-header modal-header">
                            <button type="button" class="close" @click="closeModal">&times;</button>
                        </div>
                        <div class="modal-body popup-body">
                            <img
                                v-if="thankYouMode"
                                src="../../../assets/images/wishlist/thanks.png"
                                alt="thanks icon"
                                class="thanks-icon mt-5 mx-auto d-block img-fluid" />
                            <h1 class="text-center popup-title mb-2">
                                {{ getTitle }}
                            </h1>
                            <h5 class="mx-auto text-center text-uppercase noto-sans-kr popup-subtitle my-3" :class="{ 'mb-5': thankYouMode }">
                                {{ getSubtitle }}
                            </h5>
                            <div class="main-area">
                                <loading v-if="isLoading"></loading>
                                <form class="popup-form mx-auto my-2" v-if="!thankYouMode && !isLoading">
                                    <div v-if="!secondScreen" class="first-screen" :class="{ 'move-to-left': moveLeft }">
                                        <div
                                            class="form-group"
                                            :class="{
                                                mandatory: isMandatoryMark(selectedShape),
                                            }"
                                            v-if="storeOnlyLead">
                                            <div class="mb-2">Choose a diamond ring type</div>
                                            <shapes @changeShape="changeShape($event)"></shapes>
                                        </div>
                                        <div class="form-group" v-if="storeOnlyLead && false">
                                            Looking for
                                            <interested-in @choose="changeInterestedIn()"></interested-in>
                                        </div>
                                        <div
                                            class="form-group"
                                            :class="{
                                                mandatory: isMandatoryMark(firstName),
                                            }">
                                            <input v-model="firstName" type="text" class="form-control rnd-input" placeholder="First Name" />
                                        </div>
                                        <div
                                            class="form-group"
                                            :class="{
                                                mandatory: isMandatoryMark(lastName),
                                            }">
                                            <input v-model="lastName" type="text" class="form-control rnd-input" placeholder="Last Name" />
                                        </div>
                                        <div
                                            class="form-group"
                                            :class="{
                                                mandatory: isMandatoryMark(phone),
                                            }">
                                            <input
                                                v-model="phone"
                                                type="text"
                                                class="form-control rnd-input"
                                                placeholder="Phone Number"
                                                min="6"
                                                max="10"
                                                @keypress="(e) => isNumber(e)" />
                                        </div>
                                        <div
                                            class="form-group"
                                            :class="{
                                                mandatory: isMandatoryMark(email, 'email'),
                                            }">
                                            <input v-model="email" type="email" class="form-control rnd-input" placeholder="E-mail Address" />
                                        </div>
                                        <div
                                            class="form-group"
                                            :class="{
                                                mandatory: isMandatoryMark(cityState, 'cityState'),
                                            }">
                                            <input v-model="cityState" type="text" class="form-control rnd-input" placeholder="City, State" />
                                        </div>
                                        <div
                                            v-if="showBudgetField"
                                            class="form-group"
                                            :class="{
                                                mandatory: isRingsPage && isMandatoryMark(budget),
                                            }">
                                            <select
                                                v-model="budget"
                                                class="form-control rnd-input select-budget"
                                                :class="{
                                                    mandatory: isMandatoryMark(budget, 'budget'),
                                                    'grey-text': budget === 'Ring Budget',
                                                }">
                                                <option value="Ring Budget" disabled>Ring Budget</option>
                                                <option value="$2000-$4000">$2000 - $4000</option>
                                                <option value="$2000-$4000">$4000 - $6000</option>
                                                <option value="$6000-$8000">$6000 - $8000</option>
                                                <option value="$8000-$10000">$8000 - $10000</option>
                                                <option value="$10000-$12000">$10000 - $12000</option>
                                                <option value="$12000-$15000">$12000 - $15000</option>
                                                <option value="$15000+">$15000+</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-block text-uppercase rnd-primary-btn" @click.prevent="moveToSecondScreen">Send</button>
                                        </div>
                                        <div v-if="showErrorMessage" class="mt-2 error-rnd text-center error-message">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        class="second-screen"
                                        :class="{
                                            'move-from-right': secondScreen,
                                        }">
                                        <div class="form-group contact-inputs">
                                            <div class="row">
                                                <select
                                                    v-model="contactDate"
                                                    class="form-group form-control rnd-input contact-date col-12 col-md-8"
                                                    :class="{
                                                        'grey-text': contactDate === 'Contact me by this date',
                                                    }">
                                                    <option value="Contact me by this date">Contact me by this date</option>
                                                    <option v-for="date in getNextThreeWorkDays" :key="date[0]" :value="date[1]">
                                                        {{ date[1] }}
                                                    </option>
                                                </select>
                                                <select
                                                    v-model="contactTime"
                                                    class="form-group form-control rnd-input contact-time col-12 col-md-3 ml-auto"
                                                    :class="{
                                                        'grey-text': contactTime === 'Time',
                                                    }">
                                                    <option value="Time">Time</option>
                                                    <option value="10:00 AM - 1:00 PM">10:00 AM - 1:00 PM</option>
                                                    <option value="1:00 PM - 3:00 PM">1:00 PM - 3:00 PM</option>
                                                    <option value="3:00 PM - 6:00 PM">3:00 PM - 6:00 PM</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <select
                                                    v-model="contactBy"
                                                    class="form-control rnd-input contact-by"
                                                    :class="{
                                                        'grey-text': contactBy === 'Best to Contact Me By',
                                                    }">
                                                    <option value="Best to Contact Me By">Best to Contact Me By</option>
                                                    <option v-for="conBy in conByArr" :key="conBy" :value="conBy">
                                                        {{ conBy }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-block text-uppercase rnd-primary-btn" @click.prevent="sendForm">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="store-info my-4 mx-auto" v-if="currentStore && !secondScreen">
                                <div class="row">
                                    <div class="col-0 col-md-5 d-none d-md-block" v-if="currentStore && !currentStore.onlyLead">
                                        <img
                                            v-if="currentStore && currentStore.images && currentStore.images.logo"
                                            :src="currentStore.images.logo"
                                            alt="store logo"
                                            class="store-logo mx-md-auto m-auto d-block img-fluid" />
                                    </div>
                                    <div class="col-12 col-md-12" v-if="false && currentStore && currentStore.address">
                                        <p class="noto-sans-14"></p>
                                        <div class="font-weight-bold">Location</div>
                                        <p>{{ currentStore.fullAddress }}</p>
                                        <opening-hours-display headline="Opening Hours" :hours-object="getHoursObject"></opening-hours-display>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="modal-backdrop show"></div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getDayStr, getMonthStr } from "../../helpers";
import axios from "axios";
import Shapes from "./Shapes";
import Loading from "../../components/Loading";
import InterestedInVue from "./InterestedIn";
import OpeningHoursDisplayVue from "../../components/OpeningHoursDisplay";

export default {
    name: "popup",
    props: ["products"],
    components: {
        loading: Loading,
        "opening-hours-display": OpeningHoursDisplayVue,
        "interested-in": InterestedInVue,
        Shapes,
    },
    data() {
        return {
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            cityState: null,
            budget: "Ring Budget",
            budgetDefault: "Ring Budget",
            contactTime: "Time",
            contactBy: "Best to Contact Me By",
            contactDate: "Contact me by this date",
            conByArr: ["Email", "Phone call", "Text Message"],
            selectedShape: null,
            interestedIn: "ring",
            thankYouMode: false,
            isLoading: false,
            error: null,
            showErrorMessage: false,
            msg: "Hello",
            updateForm: false,
            updateTimeoutID: null,
            secondScreen: false,
            moveLeft: false,
            isFirstSent: false,
            isSent: false,
        };
    },
    mounted() {
        this.sendGaCheckoutEvent();
        if (!this.popup_fp) this.getLivePopup();
    },
    computed: {
        ...mapState("willyou", ["baseApiUrl", "currentStore", "currentDiamond", "storeMode", "isDiamondsPage", "iframeMode", "popup_fp"]),
        showBudgetField() {
            return (this.diamonds && this.diamonds[0] && this.diamonds[0].TotalPrice === null) || (this.rings && this.rings[0]) || this.storeOnlyLead;
        },
        tooltipMessage() {
            return this.isRingsPage
                ? "Knowing your engagement ring budget will help us find the right diamond <b>for you</b>."
                : "Knowing your budget will help us make sure this is the right diamond <b>for you</b>.";
        },
        insideStore() {
            return typeof this.$route.params.url !== "undefined" && !this.storeOnlyLead;
        },
        storeOnlyLead() {
            return this.$route.name === "home-store" && this.currentStore && this.currentStore.onlyLead;
        },
        isIframeMode() {
            return this.iframeMode === true;
        },
        isRingsPage() {
            return this.$route.name === "product-ring" || this.$route.name === "engagement-rings-search-product-ring";
        },
        rings() {
            return this.isStoreMode
                ? []
                : this.products && !this.storeOnlyLead && this.products.filter((p) => p && typeof p.ringColor !== "undefined")
                ? this.products.filter((p) => p && p.ringColor)
                : [];
        },
        diamonds() {
            return this.isStoreMode && !this.storeOnlyLead
                ? [this.currentDiamond]
                : this.products && this.products.filter((p) => p && typeof p.ringColor === "string")
                ? this.products.filter((p) => p && !p.ringColor)
                : [];
        },
        getHoursObject() {
            return this.currentStore.hours;
        },
        getTitle() {
            return this.thankYouMode ? "Thank You!" : "Contact Jeweler";
        },
        getSubtitle() {
            return this.thankYouMode ? "THE JEWELER WILL CONTACT YOU SHORTLY." : this.getSubtitleAccordingToPageType;
        },
        isStore() {
            return this.currentStore && this.currentStore.companyName && this.currentStore.companyName !== "Willyou.net";
        },
        // isRingsPage() {
        //   return this.$route.name === "engagement-rings-search-product-ring";
        // },
        isDiamPage() {
            return this.$route.name === "diamonds-page-schedule-popup";
        },
        getSubtitleAccordingToPageType() {
            if (this.secondScreen) return "What time is most comfortable for you to talk?";
            else if (!this.isStore && (this.isRingsPage || this.isDiamPage))
                return "After receiving your information, we will locate a local jeweler to contact you.";

            return "The jeweler will contact you shortly after receiving your info.";
        },
        isValidMail() {
            return this.email !== null && this.email !== "" && /\S+@\S+\.\S+/.test(this.email);
        },
        isFormValid() {
            return !this.errorMessage;
        },
        isStoreMode() {
            return this.storeMode === true;
        },
        isStonePage() {
            return this.$route.name === "jeweler-schedule-popup";
        },
        errorMessage() {
            switch (true) {
                case this.storeOnlyLead && !this.selectedShape:
                    return "Please choose a diamond ring type";
                case !this.firstName:
                    return "Please enter a first name";
                case !this.lastName:
                    return "Please enter a last name";
                case !this.phone:
                    return "Please enter a valid phone number";
                case !this.isValidMail:
                    return "Please enter a valid email";
                case (!this.products || !this.products.length) && !this.storeOnlyLead:
                    return "Please choose a product";
                case !this.cityState || !this.isValidCityState:
                    return "Please enter your City, State";
                case this.showBudgetField && this.budget === this.budgetDefault:
                    return "Knowing your budget will help us find the right one for you.";

                default:
                    return null;
            }
        },
        diamondsForGa() {
            return !this.diamonds[0]
                ? []
                : [
                      {
                          name: this.diamonds[0].name,
                          id: this.diamonds[0].internal_lot,
                          price: this.diamonds[0].TotalPrice,
                          brand: this.currentStore ? this.currentStore.name : "diamonds",
                          category: this.diamonds[0].Clarity,
                          variant: this.diamonds[0].Shape,
                          quantity: 1,
                      },
                  ];
        },
        ringsForGa() {
            return !this.rings[0]
                ? []
                : [
                      {
                          name: this.rings[0].name,
                          id: this.rings[0].internalID,
                          price: null,
                          brand: this.currentStore ? this.currentStore.name : "rings",
                          category: this.rings[0].ringColor,
                          variant: this.rings[0].diamShape,
                          quantity: 1,
                      },
                  ];
        },
        ga_getProducts() {
            return this.ringsForGa.concat(this.diamondsForGa);
        },
        ga_getAffiliation() {
            return this.$route.name === "schedule-popup" ? "live-diamond-feed" : "willyou";
        },
        getAvgBudget() {
            let budgetFrom = this.budget.split("-")[0]
                ? parseInt(this.budget.split("-")[0].replace("$", ""))
                : parseInt(this.budget.split("+")[0].replace("$", ""));
            let budgetTo = this.budget.split("-")[1] ? parseInt(this.budget.split("-")[1].replace("$", "")) : null;
            return budgetTo ? (budgetFrom + budgetTo) / 2 : budgetFrom;
        },
        getDataQuery() {
            return {
                sourceUrl: this.$route.fullPath,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                email: this.email,
                cityState: this.cityState,
                budget: this.budget,
                interestedIn: this.interestedIn === "ring" ? "ring" : "diamond",
                selectedShape: this.selectedShape,
                storeurl: this.$route.params.url ? this.$route.params.url : this.isDiamondsPage ? "diamonds" : null,
                rings: this.rings,
                diamonds: this.diamonds,
                popup_fp: this.popup_fp,
                contactDate: this.contactDate,
                contactTime: this.contactTime,
                contactBy: this.contactBy,
                doneFirstStep: this.isFirstSent,
                doneSecondStep: this.isSent,
            };
        },
        getNextThreeWorkDays() {
            let dates = [];
            // set start week on monday
            for (let i = 1; i < 7; i++) {
                let date = new Date();
                // assert day is correct
                const day = date.getDay() + i > 6 ? date.getDay() + i - 7 : date.getDay() + i;
                if (day !== 0 && day !== 6 && dates.length < 3) {
                    date.setDate(date.getDate() + i);
                    const dateDay = getDayStr(day);
                    const dateDates = `${getMonthStr(date.getMonth())} ${date.getDate()}`;
                    dates.push([i, `${dateDay}, ${dateDates}`]);
                }
            }
            return dates;
        },
        isValidCityState() {
            const baseCond = this.cityState !== "" && this.cityState.length >= 3;
            const splittedVal = this.cityState.split(" ");
            return baseCond && splittedVal.length && splittedVal.length >= 1 && splittedVal[0].length > 2;
        },
    },
    watch: {
        updateForm(newVal) {
            // set timeout execution
            if (newVal === true) {
                this.updateLivePopup(this.getDataQuery);
                this.updateForm = false;
            }
        },
        getDataQuery: {
            deep: true,
            handler(newVal, oldVal) {
                if (this.updateTimeoutID !== null) clearTimeout(this.updateTimeoutID);

                // only if same fp - update row
                if (newVal.popup_fp === oldVal.popup_fp && !newVal.doneSecondStep) this.updateTimeoutID = this.updateTimeout();
            },
        },
    },
    methods: {
        ...mapActions("willyou", ["getLivePopup", "updateLivePopup"]),
        isMandatoryMark(inputValue, fieldType = null) {
            if (this.showErrorMessage === true) {
                return (
                    inputValue === null ||
                    inputValue === "" || // Has no input
                    (fieldType === "email" && !this.isValidMail) || // OR email field with false address
                    (fieldType === "budget" && this.showBudgetField && this.budget === this.budgetDefault) ||
                    (fieldType === "cityState" && !this.isValidCityState)
                ); // OR budget field is shown with default budget
            }
            return false;
        },
        changeShape(shapeToChoose) {
            this.selectedShape = shapeToChoose;
        },
        closeModal() {
            return !this.isStoreMode && !this.isStonePage && !this.isRingsPage && !this.storeOnlyLead
                ? this.$router.push({
                      name: "wishlist",
                  })
                : this.$emit("closePopup");
        },
        isNumber(evt) {
            const charCode = evt.which || evt.keyCode;
            return (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) || (this.phone && this.phone.toString().length > 9)
                ? evt.preventDefault()
                : true;
        },
        changeInterestedIn(newVal) {
            this.interestedIn = newVal;
        },
        moveToSecondScreen() {
            this.showErrorMessage = true;
            if (this.isFormValid) {
                this.sendGaPurchaseEvent(this.popup_fp);
                this.moveLeft = true;
                setTimeout(() => {
                    this.moveLeft = false;
                    this.secondScreen = true;
                }, 500);

                this.isFirstSent = true;
                this.updateLivePopup(this.getDataQuery);
            }
        },
        sendForm() {
            this.showErrorMessage = true;
            this.isLoading = true;

            if (!this.isFormValid) return (this.isLoading = false);
            this.isSent = true;

            axios
                .post(`${this.baseApiUrl}/schedule`, this.getDataQuery)
                .then((response) => {
                    response.data.success ? (this.thankYouMode = true) : null; // is TNX mode
                    this.sendGaPurchaseEvent(response.data.transactionId); // send GA
                    this.getLivePopup(); // set new popup

                    // reset for the next filled form
                    this.isLoading = false;
                    this.isFirstSent = false;
                    this.isSent = false;
                })
                .catch((error) => {
                    this.error = error;
                    this.isLoading = false;
                });
        },
        sendGaCheckoutEvent() {
            const checkoutEventObj = {
                event: "checkout",
                ecommerceLabel: "checkout",
                cartValue: null,
                ecommerce: {
                    checkout: {
                        actionField: {
                            action: "checkout",
                        },
                        products: this.ga_getProducts,
                    },
                },
            };
            window.dataLayer.push(checkoutEventObj);
        },
        sendGaPurchaseEvent(transactionId = 0) {
            const purchaseEventObj = {
                event: "purchase",
                ecommerceLabel: transactionId, // 1
                transaction_id: transactionId,
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: transactionId,
                            affiliation: this.getAffiliation,
                            revenue:
                                this.ga_getProducts[0] && this.ga_getProducts[0].price > 0
                                    ? this.ga_getProducts[0].price
                                    : this.budget === "Ring Budget"
                                    ? 0
                                    : this.getAvgBudget,
                            tax: 0,
                            shipping: 0,
                        },
                        products: this.ga_getProducts,
                    },
                },
            };
            window.dataLayer.push(purchaseEventObj);
        },
        updateTimeout() {
            return setTimeout(() => {
                this.updateForm = true;
            }, 5000);
        },
    },
};
</script>

<style lang="scss">
#popup {
    .modal-content {
        height: auto;
        overflow: hidden;
    }
    .popup-header {
        border: none;
    }

    .stick-to-top {
        align-items: flex-start;
    }

    .popup-body {
        padding-right: 80px;
        padding-left: 80px;
        max-height: 520px;
    }
    .high-form {
        min-height: 800px;
    }
    .mandatory::after {
        content: "*";
        color: #ff4d68;
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 16px;
    }
    .mandatory {
        position: relative;
        outline: 1px solid #ff4d68;
    }
    .grey-text {
        color: #757e85;
    }
    option {
        color: #52585f;
    }
    option[value="Choose your budget $"] {
        color: #757e85;
    }
    .form-group {
        position: relative;
    }
    .info {
        position: absolute;
        left: -40px;
        top: 15px;
        color: white;
        background: #434584;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 28px;
        font-weight: 900;
        font-size: 19px;
        border-radius: 333px;
        font-family: "noto serif sc", serif;
        zoom: 0.7;
        cursor: default;
    }
    .info:hover ~ .info-box {
        opacity: 1;
    }
    .info-box {
        top: -60px;
        background: rgb(255, 255, 255);
        padding: 5px;
        margin-top: 1rem;
        width: 100%;
        min-height: 45px;
        text-align: center;
        line-height: 30px;
        opacity: 0;
        transition: 0.1s all;
        color: rgba(0, 0, 0, 0.87);
    }

    @media (min-height: 650px) {
        .popup-body {
            max-height: 580px;
        }
    }

    @media (min-height: 700px) {
        .popup-body {
            max-height: 660px;
        }
    }

    @media (min-height: 780px) {
        .popup-body {
            max-height: 700px;
        }
    }

    .thanks-icon {
        width: 80px;
        height: auto;
    }

    .store-info {
        max-width: 320px;
        font-size: 14px;
        font-family: "Noto Sans KR", sans-serif;
    }

    .popup-form {
        max-width: 320px;
    }

    .popup-title {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.87);
        font-family: "Noto Serif SC", serif;
    }

    .popup-subtitle {
        color: rgba(0, 0, 0, 0.87);
        font-size: 15px;
        max-width: 300px;
    }

    .store-logo {
        width: 120px;
        height: auto;
    }

    .error-rnd {
        color: #ff4d68;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 14px;
        height: 20px;
    }

    .contact-inputs {
        .row {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }

    .contact-time {
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    .first-screen {
        max-height: 100vh;
        transition: all 0.25s linear;
        transform: translateX(0);
    }

    .second-screen {
        max-height: auto;
        transform: translateX(0);
        animation: moveFromRight 0.45s linear;
    }

    .move-to-left {
        max-height: 300px;
        transform: translateX(-150%);
    }

    @keyframes moveFromRight {
        from {
            transform: translateX(150%);
        }
        to {
            transform: translateX(0);
        }
    }
}
</style>
